










































































































































::v-deep {
  .dialogue_comm {
    position: relative;
  }
  .box_dialogue {
    position: absolute;
    left: 0;
    top: 0;
    white-space: pre-line;
  }
}
